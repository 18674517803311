import { Box, Grid } from "@mui/material";
import classes from "./layout.module.css";
import { Outlet, useNavigate } from "react-router-dom";
import Sidebar from "../components/users/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { errorState, successState } from "../redux/reducers/UserReducer";
import UserHeaderPart from "../components/users/headerPart";

function UserLayout() {
  const { sidebarToggle, errorAlert, successAlert } = useSelector(
    (state) => state.user
  );
  const [open,setOpen]=useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const _token = JSON.parse(localStorage.getItem("userToken"));

  useEffect(() => {
    if (errorAlert) {
      localStorage.removeItem("error");
      toast.error(errorAlert, {
        onClose: () => dispatch(errorState(null)),
        autoClose: 3000,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorAlert]);

  useEffect(() => {
    if (successAlert) {
      localStorage.removeItem("error");
      toast.success(successAlert, {
        onClose: () => dispatch(successState(null)),
        autoClose: 3000,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successAlert]);

  useEffect(() => {
    if (!_token) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handletoggle=()=>{
    setOpen(!open)
  }
  console.log(open);
  
  return (
    <Box className={classes.UserMainLayoutDiv}>
      {/* <Grid item xs={0} sm={0} md={sidebarToggle ? 0.8 : 2}>
                    <Sidebar />
                </Grid>
                <Grid item xs={12} sm={12} md={sidebarToggle ? 11.2 : 10}>
                <Outlet />
                </Grid> */}

      <div className={classes.sideBarContainer}>
        <Sidebar setOpen={setOpen} open={open} />
      </div>

      <div className={classes.outletContainer}>
        {/* <div>
          <UserHeaderPart />
        </div> */}
        <Outlet  context={{ open, setOpen }}/>
      </div>
      <ToastContainer />
    </Box>
  );
}

export default UserLayout;
