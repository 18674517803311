import React from "react";
import { RotatingLines } from "react-loader-spinner";

const Loader = ({width}) => {
  return (
      <RotatingLines
        visible={true}
        height={width}
        width={width}
        color="#65457f"
        strokeColor="#65457f"
        strokeWidth="5"
        animationDuration="0.75"
        ariaLabel="rotating-lines-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
  );
};

export default Loader;
