import React, { useEffect, useState } from "react";
import classes from "./aicomponent.module.css";
import { v7 } from "./commonFunction";
import mqtt from "mqtt";
import { composeMailByAi } from "../../api/Api";
import { Button } from "@mui/material";
import { BiCloset, BiSend } from "react-icons/bi";
import { MdClose } from "react-icons/md";
import { ThreeDots } from "react-loader-spinner";
import send_icon from "../../assets/icons/Send Icon.svg"
const Aicomponent = ({ setAiComponent, setMailDetail, getMailDetall }) => {
  const [aiChatID, setAiChatID] = useState("");
  const [chat, setChat] = useState([]);
  const [hasSentFirstMessage, setHasSentFirstMessage] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [loading, setloading] = useState(false);
  const [topic, setTopic] = useState("");
  const handleSendMessage = async () => {
    setloading(true);
    if (newMessage.trim()) {
      const updatedChat = [...chat, { role: "user", content: newMessage }];
      setChat(updatedChat);
      setNewMessage("");
      const data = {
        message: updatedChat,
        messageid: aiChatID || v7(),
      };

      await composeMailByAi(data);
    }
  };

  useEffect(() => {
    if (!aiChatID) {
      const id = v7();
      setAiChatID(id);
    }

    const client = mqtt.connect(
      "wss://b50ce94deaad43e698243faccb466fe5.s1.eu.hivemq.cloud:8884/mqtt",
      {
        clientId: `clientId-${Math.random().toString(16).substr(2, 8)}`,
        username: "mjraghavan",
        password: "j!v#!AjRyaL62Du",
      }
    );

    client.on("connect", () => {
      console.log("Connected to MQTT broker");
      client.subscribe(`composemailchat/${aiChatID}`, { qos: 1 }, (error) => {
        setTopic(`composemailchat/${aiChatID}`);
        if (error) {
          console.error("Subscription error:", error);
        } else {
          console.log(`Subscribed to topic: composemailchat/${aiChatID}`);
        }
      });
    });

    client.on("message", (topic, message) => {
      const composemailchat = JSON.parse(message);
      const aiMessage = {
        role: "assistant",
        content: composemailchat.ai.message_to_user,
      };

      if (composemailchat.ai.draftMail) {
        aiMessage.draftMail = composemailchat.ai.draftMail;
      }

      setChat((prevChat) => [...prevChat, aiMessage]);
      setloading(false);
    });

    client.on("error", (err) => {
      console.error("Connection error:", err);
    });

    client.on("close", () => {
      console.log("Disconnected from MQTT broker");
    });

    return () => {
      if (client.connected) {
        client.end();
      }
    };
  }, [aiChatID]);

  function formatText(input) {
    return input.replace(/\n/g, "<br>").replace(/\*/g, "");
  }
  const handleGetMail = async () => {
    const lastmessage = chat[chat.length - 1];
    const data = {
      to: lastmessage.draftMail?.to,
      subject: lastmessage?.draftMail.subject,
      body: lastmessage?.draftMail.body,
    };
    setMailDetail(data);
  };

  const firstMessgeSend = async (id, message) => {
    try {
      const updatedChat = [
        ...chat,
        { role: "user", content: message ? message : "I want to create mail." },
      ];
      const data = {
        message: updatedChat,
        messageid: id,
      };

      await composeMailByAi(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    console.log(getMailDetall);

    if (topic && !hasSentFirstMessage) {
      let message;
      if (getMailDetall.from || getMailDetall.to || getMailDetall.subject) {
        message = `
        ${getMailDetall.from ? `From: ${getMailDetall.from} <br />` : ""}
        ${getMailDetall.to ? `To: ${getMailDetall.to} <br />` : ""}
        ${
          getMailDetall.subject
            ? `Subject: ${getMailDetall.subject} <br />`
            : ""
        }
        ${getMailDetall.emailData ? `${getMailDetall.emailData}` : ""}
      `;
      }
      setloading(true);
      const updatedChat = [
        ...chat,
        { role: "user", content: message ? message : "I want to create mail." },
      ];
      setChat(updatedChat);
      firstMessgeSend(aiChatID, message);
      setHasSentFirstMessage(true);
    }
  }, [topic, hasSentFirstMessage, getMailDetall]);
  const handleClose = () => {
    setAiComponent(false);
    setAiChatID("");
    setTopic("");
    setHasSentFirstMessage(false);
  };
  return (
    <div className={classes.aicomponent}>
      <div className={classes.closebutton} onClick={handleClose}>
        <MdClose />
      </div>
      <div className={classes.chatContainer}>
        {chat.map((message, index) => (
          <div
            key={index}
            className={
              message.role === "user" ? classes.myMessage : classes.aiMessage
            }
          >
            <div dangerouslySetInnerHTML={{ __html: message.content }} />
            {message?.draftMail?.body && (
              <div className={classes.draftMail}>
                <p>
                  <strong>To:</strong> {message.draftMail.to}
                </p>
                <p>
                  <strong>Subject:</strong> {message.draftMail.subject}
                </p>
                <p>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: formatText(message.draftMail.body),
                    }}
                  />
                </p>
                <Button variant="primary" type="button" onClick={handleGetMail}>
                  Insert
                </Button>
              </div>
            )}
          </div>
        ))}
        {loading && (
          <ThreeDots
            visible={true}
            height="40"
            width="40"
            color="#5f4784"
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        )}
      </div>
      <div className={classes.inputContainer}>
        <textarea
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type your message..."
          className={classes.input}
        />
    
        <img src={send_icon} className={classes.sendButton} onClick={handleSendMessage} />

      </div>
    </div>
  );
};

export default Aicomponent;
