import { Box, Typography, Button, Avatar, Link, Container } from "@mui/material";
import classes from "./user.module.css";
import "./users.css";
import { useDispatch, useSelector } from "react-redux";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import {
  getConversationInfo,
  getConversationMsgs,
  getGoogleAuthnticationToken,
  sendMessage,
  uploadFile,
} from "../../api/Api";
import { useEffect, useRef, useState } from "react";
import {
  errorState,
  updateConversationInfo,
} from "../../redux/reducers/UserReducer";
import { formatDuration, splitFirstLetter } from "../../utils/helper";
import mqtt from "mqtt";
import Picker from "emoji-picker-react";
import { useLocation, useNavigate } from "react-router-dom";
import { MdOutlineHail } from "react-icons/md";
import CreatePop from "../popup/CreatePop";

import MessageContainer from "./meesageContainer";
import MessageInput from "./MessageInput";
import SubSideBar from "./subSideBar";

function UserPage() {
  const [value, setValue] = useState("");
  const [myFile, setMyFile] = useState({
    file_name: "",
    file_url: "",
    file_type: "",
    file_size: 0,
  });
  const location = useLocation();
  const { state } = location;
  const containerRef = useRef(null);
  const [userMsgs, setUserMsgs] = useState([]);
  const nameArr = state?.conversationInfo?.name?.split("-");
  const [initialsMsgs, setInitialMsgs] = useState([]);
  const [client, setClient] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [message, setMessage] = useState("");
  const { convoInfo } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const _token = JSON.parse(localStorage.getItem("userToken"));
  const [trackHeight, setTrackHeight] = useState("");
  const [isPaginated, setIsPaginated] = useState(false);
  const [page, setPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [showPicker, setShowPicker] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const endOfMessagesRef = useRef(null);
  const [createPopup, setCreatePopup] = useState(false);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen); // Toggle between true and false
  };

  const onEmojiClick = (event, emojiObject) => {
    setValue((prevInput) => prevInput + event?.emoji);
    // setShowPicker(false);
  };

  const handleKeyPress = (e) => {
    if (e?.key == "Enter" && value?.length > 0) {
      handleSendMsg();
    }
  };
  const handleConversationInfo = async () => {
    let result = await getConversationInfo();
    if (result?.status === 201 || result?.status === 200) {
      dispatch(updateConversationInfo(result?.data?.data));
    } else {
      dispatch(
        errorState(
          result?.data?.message ? result?.data?.message : result?.statusText
        )
      );
    }
  };

  const handleConversationMsgs = async () => {
    setTrackHeight(containerRef?.current?.scrollHeight);

    let result = await getConversationMsgs(state?.conversationInfo?._id, page);
    if (result?.status === 201 || result?.status === 200) {
      setInitialMsgs(result?.data?.data);
    } else {
      setInitialMsgs([]);
      dispatch(
        errorState(
          result?.data?.message ? result?.data?.message : result?.statusText
        )
      );
    }
  };

  const handleSendMsg = async (uplaod_info) => {
    let body = {
      conversation_id: state?.conversationInfo?._id,
      message: value,
      timeOffset: new Date().getTimezoneOffset(),
    };
    if (uplaod_info) {
      body["file_name"] = uplaod_info?.originalname;
      body["file_url"] = `uploads/${uplaod_info?.filename}`;
      body["file_type"] = uplaod_info?.mimetype;
      body["file_size"] = uplaod_info?.size;
    }
    if (!body?.file_name && body?.message?.length === 0) {
      alert("Please enter a message");
    } else {
      let result = await sendMessage(body);
      if (result?.status === 201 || result?.status === 200) {
        setIsPaginated(false);
        handleConversationMsgs();
        setValue("");
        // dispatch(updateConversationInfo(result?.data?.data[0]));
      } else {
        dispatch(
          errorState(
            result?.data?.message ? result?.data?.message : result?.statusText
          )
        );
      }
    }
  };

  const handleScroll = () => {
    if (containerRef.current.scrollTop === 0 && !loading) {
      setPage((prevPage) => prevPage + 10);
      setIsPaginated(true);
    }
  };

  useEffect(() => {
    if (_token) {
      handleConversationInfo();
    }
  }, []);

  const getAuthorize = async (query) => {
    let result = await getGoogleAuthnticationToken(query);
    if (result?.status === 201 || result?.status === 200) {
      setIsPaginated(false);
      setValue("");
      if (convoInfo && convoInfo?.length > 0) {
        navigate(`/user-page/${convoInfo[0]?.conversationInfo?._id}`, {
          state: convoInfo[0],
        });
      }
      // dispatch(updateConversationInfo(result?.data?.data[0]));
    } else {
      dispatch(
        errorState(
          result?.data?.message ? result?.data?.message : result?.statusText
        )
      );
      if (convoInfo && convoInfo?.length > 0) {
        navigate(`/user-page/${convoInfo[0]?.conversationInfo?._id}`, {
          state: convoInfo[0],
        });
      }
    }
  };

  useEffect(() => {
    if (state?.conversationInfo?._id) {
      handleConversationMsgs();
      setMessage("");
    } else if (window.location.pathname?.includes("success")) {
    } else {
      if (convoInfo && convoInfo?.length > 0) {
        navigate(`/user-page/${convoInfo[0]?.conversationInfo?._id}`, {
          state: convoInfo[0],
        });
      }
    }
  }, [state, page, convoInfo]);

  useEffect(() => {
    if (window.location.pathname?.includes("success")) {
      const fullUrl = window.location.href;
      const urlObj = new URL(fullUrl);
      const queryParams = new URLSearchParams(urlObj.search);
      console.log(queryParams, urlObj.search, "data before obj");
      getAuthorize(urlObj.search);
    }
  }, [window.location.pathname]);

  useEffect(() => {
    const clientId = `clientId-${Math.random().toString(16).substr(2, 8)}`;

    const mqttClient = mqtt.connect(
      "wss://b50ce94deaad43e698243faccb466fe5.s1.eu.hivemq.cloud:8884/mqtt",
      {
        clientId: clientId,
        username: "mjraghavan",
        password: "j!v#!AjRyaL62Du",
      }
    );

    mqttClient.on("connect", () => {
      setIsConnected(true);
      if (convoInfo && convoInfo.length > 0) {
        convoInfo.map((convo) => {
          console.log(`my id = /my_genio/${convo?.conversationInfo?._id}`);
          mqttClient.subscribe(
            `/my_genio/${convo?.conversationInfo?._id}`,
            (err) => {
              if (!err) {
                console.log(`Subscribed to topic: test/topic`);
              }
            }
          );
        });
      }
    });

    mqttClient.on("message", (topic, payload) => {
      console.log(payload, "From MQTT");
      const pathway = window.location.pathname.split("/");
      if (topic === `/my_genio/${pathway[pathway.length - 1]}`) {
        console.log("payload = ", payload.toString());
        setMessage(payload.toString());
      }
    });

    mqttClient.on("error", (err) => {
      console.error("Connection error: ", err);
      mqttClient.end();
    });
    setClient(mqttClient);
    return () => {
      if (client) {
        client.end();
      }
    };
  }, [convoInfo]);

  useEffect(() => {
    getSetMesages();
  }, [message, initialsMsgs]);

  const getSetMesages = async () => {
    if (message) {
      // Exit early if there's no message
      console.log("Received message = ", message);
      let parsedMessage;
      try {
        parsedMessage = JSON.parse(message);
        console.log("Parsed message = ", parsedMessage);
      } catch (error) {
        console.error("Error parsing message:", error);
        return;
      }

      let existing_msgs = [...initialsMsgs];
      console.log("Initial messages = ", existing_msgs);
      const randomexistIndex = existing_msgs.findIndex(
        (item) => item?._id === parsedMessage?._id
      );
      const randomexist = existing_msgs[randomexistIndex];
      let find_dup = existing_msgs[existing_msgs.length - 2];
      let find_dup_two = existing_msgs[existing_msgs.length - 1];
      console.log("Checking duplicates", find_dup, find_dup_two);

      // If it's a new message (not found in the last two messages)
      if (
        find_dup?._id !== parsedMessage?._id &&
        find_dup_two?._id !== parsedMessage?._id &&
        !randomexist // Ensure the message isn't already present
      ) {
        console.log("New message, adding to state.");
        existing_msgs.push(parsedMessage);
        setUserMsgs(existing_msgs);
      }
      // If it's an existing message but with different content, update it
      else if (randomexist) {
        console.log("Message exists, updating it.");
        let isJson = false;
        let parsedOldMessage, messagesArray;
        // Check if the existing message is an array (stringified or not)
        try {
          parsedOldMessage = JSON.parse(randomexist?.message);
          isJson = Array.isArray(parsedOldMessage);
        } catch (error) {
          isJson = false;
        }
        // If it's already a JSON array, add the new message to it
        if (isJson) {
          messagesArray = [...parsedOldMessage, parsedMessage?.message];
        } else {
          // Otherwise, create a new array with the old and new messages
          messagesArray = [randomexist?.message, parsedMessage?.message];
        }

        // Update the message at the found index
        existing_msgs[randomexistIndex] = {
          ...randomexist,
          message: JSON.stringify(messagesArray), // Store the updated message as a JSON string
        };

        // Update state with the modified messages array
        setUserMsgs(existing_msgs);
      }
      // If it's a duplicate or unchanged message, just update the state
      else {
        console.log("No changes in messages.");
        setUserMsgs(existing_msgs);
      }
    } else {
      console.log("No changes in messages.");
      setUserMsgs([...initialsMsgs]);
    }
  };

  useEffect(() => {
    if (endOfMessagesRef?.current && !isPaginated) {
      endOfMessagesRef.current.scrollIntoView({ behavior: "smooth" });
      setIsPaginated(false);
    } else if (containerRef.current) {
      const newScrollHeight = containerRef.current.scrollHeight;
      containerRef.current.scrollTop = newScrollHeight - trackHeight;
    }
  }, [initialsMsgs.length, message, initialsMsgs, userMsgs]);

  return (
    <div className={classes.MainContainer}>
      <SubSideBar
        convoInfo={convoInfo}
        handleConversationInfo={handleConversationInfo}
      />
      <Box className={classes.content}>
        <Box className={classes.user_container}>
         
            <MessageContainer
              userMsgs={userMsgs}
              handleScroll={handleScroll}
              initialsMsgs={initialsMsgs}
              setInitialMsgs={setInitialMsgs}
              setIsPaginated={setIsPaginated}
              formatDuration={formatDuration}
              endOfMessagesRef={endOfMessagesRef}
              handleConversationInfo={handleConversationInfo}
              setValue={setValue}
              containerRef={containerRef}
            />
        
          {Array.isArray(userMsgs) && userMsgs.length === 0 && (
            <Box className={classes.welcome_part}>
              <Box>
                <MdOutlineHail />
                <Typography>
                  Hello! I'm here to help. What would you like to chat about
                  today?
                </Typography>
              </Box>
            </Box>
          )}
          <Container container className={classes.text_part} >
            <MessageInput
              value={value}
              setValue={setValue}
              showPicker={showPicker}
              setShowPicker={setShowPicker}
              setMyFile={setMyFile}
              myFile={myFile}
              handleSendMsg={handleSendMsg}
              handleKeyPress={handleKeyPress}
            />
            {showPicker && (
              <Box className={classes.emoji_picker}>
                <Picker
                  pickerStyle={{ width: "100%" }}
                  onEmojiClick={(e, newEmoji) => onEmojiClick(e, newEmoji)}
                  searchDisabled={true}
                />
              </Box>
            )}
          </Container>
        </Box>
      </Box>
    </div>
  );
}

export default UserPage;
