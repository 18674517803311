import { createSlice } from "@reduxjs/toolkit";
import { set } from "rsuite/esm/internals/utils/date";

const emailSclices = createSlice({
  name: "email",
  initialState: {
    emailloading: false,
    emailList: [],
  },
  reducers: {
    setEmailLoading: (state, action) => {
      state.emailloading = true;
    },
    setEmailListSuccess: (state, action) => {
      state.emailloading = false;
      state.emailList = action.payload;
    },
    setEmailListError: (state, action) => {
      state.emailloading = false;
      state.emailList = action.payload;
    },
  },
});

export const { setEmailLoading, setEmailListSuccess, setEmailListError } =
  emailSclices.actions;
export default emailSclices.reducer;
