import React, { useEffect, useState } from "react";
import classes from "./email.module.css";
import { Avatar, Box, Button, Typography } from "@mui/material";
import {
  FaChevronCircleLeft,
  FaChevronCircleRight,
  FaPlus,
} from "react-icons/fa";
import { useNavigate, useOutletContext } from "react-router-dom";
import { splitFirstLetter } from "../../utils/helper";
import { MdDelete } from "react-icons/md";
import ConfirmationPop from "../../popups/ConfirmationPop";
import { useDispatch } from "react-redux";
import { MdAddCircle } from "react-icons/md";
import { deleteConversation } from "../../api/Api";
import { errorState, successState } from "../../redux/reducers/UserReducer";
import { FaPencilAlt } from "react-icons/fa";
import CreatePop from "../popup/CreatePop";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const EmailSubSideBar = ({
  emailsList,
  setSelectedEmail,
  setSecSidebaropen,
  setComposeOpen,
  selectedEmail
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [createPopup, setCreatePopup] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { open, setOpen } = useOutletContext();

  const toggleSidebar = () => {
    setSecSidebaropen(true); // Toggle between true and false
  };
  return (
    <Box
      className={`${classes.sidebar} ${open ? classes.open : classes.closed}`}
    >
      <Button
        className={classes.toggleButton}
        onClick={() => {
          setOpen(!open);
          setSecSidebaropen(false);
        }}
      >
        {open ? (
          <IoIosArrowBack className={classes.toggleIcon} />
        ) : (
          <IoIosArrowForward className={classes.toggleIcon} />
        )}
      </Button>
      <div className={classes.menuItems}>
        <div className={classes.mail_heading}>
          <Typography color={"#0061EA"} fontWeight={"Bold"}>
            Mail
          </Typography>
          <Button
            className="d-flex gap-2 text-white"
            style={{ backgroundColor: "#0061EA" }}
            onClick={() => setComposeOpen(true)}
          >
            <FaPlus /> Compose
          </Button>
        </div>
        <ul className={classes.email_all_category}>
          {emailsList &&
            emailsList.length > 0 &&
            emailsList.map((item, index) => {
              return (
                <li key={index}>
                  <Box
                    className={`${classes.sidebar_logo} ${selectedEmail === item?classes.sidebar_logo_active:""}`} 
                    key={index}
                    onClick={() => {
                      setSelectedEmail(item);
                      toggleSidebar();
                    }}
                  >
                    <div className={classes.category_details}>
                      <div className={classes.category_details_left}>
                        <Avatar
                          className={`${
                            window.location.pathname?.includes(item._id)
                              ? classes.SelectedAvatar
                              : classes.unSelectedAvatar
                          }`}
                        >
                          {splitFirstLetter(item)}
                        </Avatar>
                        <div>
                          <Typography
                            textTransform={"capitalize"}
                            className={classes.userNameText}
                          >
                            {item}
                          </Typography>
                        </div>
                      </div>
                      <div className={classes.category_details_right}>12</div>
                    </div>
                    {/* <Button
                    className={classes.deleteBinIcon}
                    onClick={(e) => handleDeleteClick(e, item)}
                  >
                    <MdDelete />
                  </Button> */}
                  </Box>
                </li>
              );
            })}
        </ul>
      </div>
      {/* <ConfirmationPop
      open={confirmationPopup}
      handleClose={() => setConfirmationPopup(false)}
      handleConfirm={handleDeleteConversation}
      description={`Are you Want to Delete Conversation "${
        selectedItem?.conversationInfo?.name.split("-")[1]
      }"`}
    /> */}
      <CreatePop open={createPopup} handleClose={() => setCreatePopup(false)} />
    </Box>
  );
};

export default EmailSubSideBar;
