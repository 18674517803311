import React, { useEffect, useRef, useState } from "react";
import classes from "./user.module.css";
import {
  Avatar,
  Box,
  Container,
  Dialog,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import copy from "copy-to-clipboard";
import { errorState, successState } from "../../redux/reducers/UserReducer";
import { useDispatch } from "react-redux";
import {
  editConversationName,
  RegenerateMessage,
  sendFeedback,
} from "../../api/Api";
import Messages from "./Messages";
import dummy_image from "../../assets/images/Avatar.jpg";
import searchIcon from "../../assets/icons/Search Messages.png";
import moreIcon from "../../assets/icons/More Icon.png";
import callIcon from "../../assets/icons/Call Icon.png";
import {
  IoIosArrowRoundBack,
  IoIosArrowRoundForward,
  IoIosCloseCircleOutline,
} from "react-icons/io";
import { Button } from "react-bootstrap";
import { set } from "rsuite/esm/internals/utils/date";

const MessageContainer = ({
  userMsgs,
  handleScroll,
  formatDuration,
  initialsMsgs,
  setInitialMsgs,
  setIsPaginated,
  handleConversationInfo,
  setValue,
  endOfMessagesRef,
  containerRef,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [convoName, setConvoName] = useState("");
  const [aiUser, setAiUser] = useState(false);
  function handleFileClick(infos) {
    let url = `https://api.mygenio.apps.org.in/api/${infos?.file_url}`;
    window.open(url, "_blank", "noopener,noreferrer");
  }
  const handleNamePopup = () => {
    setOpen(true);
  };
  useEffect(() => {
    if (userMsgs[0]?.conversation_details) {
      const name = userMsgs[0].conversation_details.name.split("-")[1];
      setConvoName(name);
      if (userMsgs[0].conversation_details.conversation_type === "private") {
        setAiUser(true);
      }
    }
  }, [userMsgs]);
  const handleclick = async () => {
    try {
      const firstName = userMsgs[0]?.conversation_details.name.split("-")[0];
      const fullName = `${firstName}-${convoName}`;
      console.log(fullName);
      const data = {
        name: fullName,
        category_id: userMsgs[0]?.conversation_details._id,
      };
      editConversationName(data);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div style={{ color: "#d9d9d94d" }}>
      <Box
        className={classes.chat_part}
        onScroll={handleScroll}
        ref={containerRef}
      >
        <Container style={{ position: "relative", padding: 0 }}>
          <div className={classes.slected_user_details}>
            <div className={classes.user_detail_left}>
              <Avatar
                alt="user image"
                src={dummy_image}
                className={classes.user_imag}
              />
              <div className={classes.left_details}>
                <p className={classes.left_details_user_name}>
                  {aiUser ? (
                    <>
                      {userMsgs[0]?.conversation_details?.name.split("-")[1]}{" "}
                      <span style={{fontSize:"14px"}}>{"(AI)"}</span>
                    </>
                  ) : (
                    "User Name"
                  )}
                </p>
                <p className={classes.left_details_lastseen}>
                  last seen 5 mins ago
                </p>
              </div>
            </div>
            <div className={classes.user_detail_right}>
              <Avatar src={searchIcon} style={{ cursor: "pointer" }} />
              <Avatar src={callIcon} style={{ cursor: "pointer" }} />
              <Avatar
                src={moreIcon}
                style={{ cursor: "pointer" }}
                onClick={() => handleNamePopup()}
              />
            </div>
          </div>
          {Array.isArray(userMsgs) &&
            userMsgs.length > 0 &&
            userMsgs?.map((datas, i) => {
              let isJson = false;
              let parsedMessage;

              try {
                parsedMessage = JSON.parse(datas?.message);
                isJson = Array.isArray(parsedMessage);
              } catch (error) {
                isJson = false;
              }
              return (
                <div key={i}>
                  {(i === 0 ||
                    !dayjs(datas.createdAt).isSame(
                      userMsgs[i - 1].createdAt,
                      "day"
                    )) && (
                    <Box textAlign={"center"} py={2}>
                      <span className={classes.duration}>
                        {formatDuration(datas.createdAt)}
                      </span>
                    </Box>
                  )}
                  <Messages
                    datas={datas}
                    index={i}
                    isJson={isJson}
                    parsedMessage={parsedMessage}
                    initialsMsgs={initialsMsgs}
                    setInitialMsgs={setInitialMsgs}
                    setIsPaginated={setIsPaginated}
                    handleConversationInfo={handleConversationInfo}
                    setValue={setValue}
                  />
                </div>
              );
            })}
          <div ref={endOfMessagesRef} />
        </Container>
      </Box>
      {open && (
        <Dialog open={true}>
          <Box sx={{ width: "500px" }}>
            <Box
              className={`d-flex-between popup-header flex-column align-items-start popup-header-3`}
            >
              <div className="d-flex align-items-center w-100 justify-content-between">
                <Typography fontSize={{ xs: "17px", sm: "18px", md: "20px" }}>
                  {" "}
                  <IoIosArrowRoundBack /> Changes Conversation Name
                </Typography>
                <IoIosCloseCircleOutline onClick={() => setOpen(false)} />
              </div>
            </Box>
            <Box p={2}>
              <TextField
                fullWidth
                value={convoName}
                onChange={(e) => setConvoName(e.target.value)}
                className="convo-field"
                placeholder="Conversation Name"
                autoComplete="off"
              />
            </Box>
            <Box textAlign={"end"} p={2}>
              <Button className="btn-one" onClick={handleclick}>
                Create
              </Button>
            </Box>
          </Box>
        </Dialog>
      )}
    </div>
  );
};

export default MessageContainer;
