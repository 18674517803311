import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import { MdAttachFile } from 'react-icons/md';

const FilePicker = ({ onFileSelected }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [open, setOpen] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedFile(null); // Reset the selected file when closing
  };

  const handleSubmit = () => {
    if (selectedFile) {
      onFileSelected(selectedFile);
    }
    handleClose();
  };

  return (
    <div>
        <MdAttachFile onClick={handleClickOpen} size={22}/>
    
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Select a File</DialogTitle>
        <DialogContent>
          <input
            type="file"
            onChange={handleFileChange}
            style={{ display: 'block', marginTop: '20px' }}
          />
          {selectedFile && (
            <Typography variant="body2" style={{ marginTop: '10px' }}>
              Selected File: {selectedFile.name}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" disabled={!selectedFile}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FilePicker;
