import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "./reducers/UserReducer";
import categoryReducer from "./reducers/categoryReducer";
import emailReducer from "../slices/emailSlices";
const reducer=combineReducers({
  user: userReducer,
  category: categoryReducer,
  emailConfig:emailReducer
})

export const MyStore = configureStore({
 reducer:reducer,
  devTools: true,
});
