import { Avatar, Box, Button, Drawer, Grid, Typography } from "@mui/material";
import classes from "./reminder.module.css";
// import  from "dayjs-plugin-utc";
import classesTwo from "../users/user.module.css";
import { deleteReminder, getConversationInfo, getMyReminders } from "../../api/Api";
import {
  errorState,
  successState,
  toggleSidebar,
  updateConversationInfo,
} from "../../redux/reducers/UserReducer";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoMdAlarm } from "react-icons/io";
import { MdDelete, MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { IoMenu } from "react-icons/io5";
import { splitFirstLetter } from "../../utils/helper";
import { FiLogOut } from "react-icons/fi";
import { IoIosClose } from "react-icons/io";
import ConfirmationPop from "../../popups/ConfirmationPop";
import NoData from "../../assets/images/no-record.png";
import { FaCalendarAlt } from "react-icons/fa";
import Logo from "../../assets/images/logo.png";
import dayjs from "dayjs";
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

function Reminder() {
  const [reminders, setReminders] = useState([]);
  const [isDrawer, setIsDrawer] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [createPopup, setCreatePopup] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const _token = JSON.parse(localStorage.getItem("userToken"));
  const { sidebarToggle, convoInfo } = useSelector((state) => state.user);

  let _colors = ["blue", "yellow", "green", "brown"];
  let j = 0;

  function handleBorderColor(index) {
    let answer;
    if (_colors[j]) {
      answer = _colors[j];
      j++;
    } else {
      j = 0;
      answer = "orange";
    }

    return answer;
  }

  const handleConversationInfo = async () => {
    let result = await getConversationInfo();
    if (result?.status === 201 || result?.status === 200) {
      dispatch(updateConversationInfo(result?.data?.data));
    } else {
      dispatch(
        errorState(
          result?.data?.message ? result?.data?.message : result?.statusText
        )
      );
    }
  };

  const handleDeleteReminder = async () => {
    setConfirmPopup(false);
    let result = await deleteReminder(selectedItem?._id);
    if (result?.status === 201 || result?.status === 200) {
      dispatch(successState(result?.data?.message));
      handleGetReminders();
    } else {
      dispatch(
        errorState(
          result?.data?.message ? result?.data?.message : result?.statusText
        )
      );
    }
  }

  const handleGetReminders = async () => {
    let result = await getMyReminders(_token?._id);
    if (result?.status === 201 || result?.status === 200) {
      let all_reminders = result?.data?.data;
      setReminders(all_reminders?.reverse());
    } else {
      dispatch(
        errorState(
          result?.data?.message ? result?.data?.message : result?.statusText
        )
      );
    }
  };
  console.log("remai =", reminders);
  useEffect(() => {
    if (_token) {
      handleConversationInfo();
      handleGetReminders();
    }
  }, []);

  const handleDeleteClick = (e, item) => {
    e.stopPropagation();
    setSelectedItem(item);
    setConfirmPopup(true);
  };

  const DrawerList = (
    <Box
      sx={{ width: 250 }}
      p={3}
      role="presentation"
      className={classesTwo.drawer_part}
    >
      <Box mt={4}>
        <Box textAlign={"center"}>
          <img src={Logo} alt="logo" width={"50%"} />
        </Box>
        {convoInfo &&
          convoInfo.length > 0 &&
          convoInfo.map((item) => {
            const { name } = item;
            const nameArr = item?.conversationInfo?.name?.split("-");
            return (
              <Box
                onClick={() =>
                  navigate(`/user-page/${item.conversationInfo._id}`, {
                    state: item,
                  })
                }
                className={classesTwo.sidebar_logo}
                bgcolor={
                  window.location.pathname?.includes(
                    item.conversationInfo._id
                  ) && "var(--primary-color-light)"
                }
              >
                <Avatar
                  sx={{
                    bgcolor:
                      window.location.pathname?.includes(
                        item.conversationInfo._id
                      ) && "#734f96",
                  }}
                >
                  {nameArr && splitFirstLetter(nameArr[1])}
                </Avatar>
                <Typography textTransform={"capitalize"}>
                  {nameArr && nameArr[1]}
                </Typography>
              </Box>
            );
          })}
        <Box
          className={classesTwo.create_new}
          onClick={() => {
            setCreatePopup(true);
            setIsDrawer(false);
          }}
        >
          Create{" "}
        </Box>
      </Box>
      <Box textAlign={"center"}>
        <Typography
          textAlign={"center"}
          mb={2}
          sx={{ cursor: "pointer" }}
          onClick={() => navigate("/reminder")}
        >
          My Reminders
        </Typography>
        <Button
          startIcon={<FiLogOut />}
          className={classesTwo.logout_btn}
          onClick={() => setConfirmPopup(true)}
          fullWidth
        >
          Logout
        </Button>
      </Box>
      <IoIosClose
        style={{
          position: "absolute",
          top: "15px",
          right: "10px",
          fontSize: "35px",
          cursor: "pointer",
        }}
        onClick={() => setIsDrawer(false)}
      />
    </Box>
  );
  return (
    <Box height={"100vh"} sx={{ overflowX: "hidden" }}>
      <Box className={classes.user_header}>
        <Typography
          display={{ xs: "flex", sm: "flex", md: "none" }}
          alignItems={"center"}
        >
          <IoMenu
            className={classes.menubar_icon}
            onClick={() => setIsDrawer(true)}
          />
        </Typography>
        <Typography
          display={{ xs: "none", sm: "none", md: "flex" }}
          alignItems={"center"}
        >
          <IoMenu
            className={classes.menubar_icon}
            onClick={() => dispatch(toggleSidebar(!sidebarToggle))}
          />
        </Typography>
        <Typography className={classes.reminder_title}>
          <IoMdAlarm /> Reminders
        </Typography>
      </Box>
      <Box bgcolor={"#D9D9D94D"} className={classes.reminder_part_parent}>
        <Box className={classes.reminder_part}>
          <Button
            className={classes.back_arrow}
            onClick={() => navigate("/user-page")}
          >
            <IoIosArrowBack /> Back to chat
          </Button>
          <Grid container columnGap={5}>
            {Array.isArray(reminders) &&
              reminders?.map((datas, index) => (
                <Grid item xs={12} sm={12} md={5}>
                  <Box
                    className={classes.single_reminder}
                    borderLeft={`5px solid ${handleBorderColor(index)}`}
                  >
                    <Typography className="d-flex" gap={"10px"}>
                      <FaCalendarAlt style={{ marginTop: "-2px" }} />{" "}
                      {dayjs(datas?.time).format("MMMM D, YYYY hh:mm A")}
                    </Typography>
                    <Typography sx={{ color: "rgba(0,0,0,0.8)" }}>
                      {datas?.title}
                    </Typography>
                    <small>
                      See details <MdOutlineKeyboardDoubleArrowRight />
                    </small>
                    <Button
                      className={classes.deleteBinIcon}
                      onClick={(e) => handleDeleteClick(e, datas)}
                    >
                      <MdDelete />
                    </Button>
                  </Box>
                </Grid>
              ))}
          </Grid>
          {Array.isArray(reminders) && reminders?.length === 0 && (
            <Box className={classes.no_record}>
              <img src={NoData} />
              <span>No Data found </span>
            </Box>
          )}
        </Box>
      </Box>
      <ConfirmationPop
        open={confirmPopup}
        handleClose={() => setConfirmPopup(false)}
        handleConfirm={handleDeleteReminder}
        description={`Are you Want to Delete Conversation "${
          selectedItem?.title
        }"`}
      />
    </Box>
  );
}

export default Reminder;
