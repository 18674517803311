import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Modal,
  Typography,
} from "@mui/material";
import classes from "./common.module.css";
import React, { useEffect, useState } from "react";
import { splitFirstLetter } from "../../utils/helper";
import { errorState, successState } from "../../redux/reducers/UserReducer";
import {
  getemailcount,
  getMyEmailsList,
  updateEmailConfigs,
} from "../../api/Api";
import { useDispatch } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
};

const EmailManagerModel = ({ open, handleClose }) => {
  const [count, setCount] = useState([]);
  const [emailsList, setEmailsList] = useState([]);
  const dispatch = useDispatch();

  const fetchEmailConfigs = async () => {
    try {
      let result = await getMyEmailsList();
      if (result?.status === 200 || result?.status === 201) {
        setEmailsList(result.data.data);

        // Fetch counts concurrently for all emails
        const countPromises = result.data.data.map((data) => getemailcount(data._id));
        const counts = await Promise.all(countPromises);
        
        setCount(counts); // Set counts to state
      } else {
        throw new Error(result?.data?.message || result?.statusText);
      }
    } catch (error) {
      dispatch(errorState(error.message));
    }
  };

  const handleSubmit = async () => {
    try {
      const updatedConfigs = emailsList.map((email) => ({
        _id: email._id,
        isActive: email.isActive,
      }));

      let result = await updateEmailConfigs({ updatedConfigs });
      if (result?.status === 200 || result?.status === 201) {
        handleClose();
        dispatch(successState("Emails Updated Successfully"));
        fetchEmailConfigs(); // Refresh email list
      } else {
        throw new Error(result?.data?.message || result?.statusText);
      }
    } catch (error) {
      dispatch(errorState(error.message));
    }
  };

  const handleChange = (e, emailId) => {
    const updatedList = emailsList.map((email) => {
      if (email._id === emailId) {
        return { ...email, isActive: e.target.checked }; // Use spread operator to return a new object
      }
      return email;
    });
    setEmailsList(updatedList);
  };

  useEffect(() => {
    fetchEmailConfigs();
  }, []);

  console.log(count);
  
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          className={classes.HeaderText}
        >
          Connected Emails
        </Typography>
        <div className={classes.menuItems}>
          <ul>
            {emailsList.length > 0 && emailsList.map((item, index) => {
              const nameArr = item?.name.split(" ");
              return (
                <li key={index}>
                  <Box className={`${classes.sidebar_logo}`} key={item._id}>
                    <Checkbox
                      checked={item.isActive}
                      onChange={(e) => handleChange(e, item._id)}
                      className={classes.checkboxMenu}
                    />
             
                      <Avatar
                        className={`${
                          window.location.pathname?.includes(item._id)
                            ? classes.SelectedAvatar
                            : classes.unSelectedAvatar
                        }`}
                        style={{ backgroundColor: item.color }}
                      >
                        {nameArr && splitFirstLetter(nameArr[0])}
                      </Avatar>
                  
                    <div>
                      <Typography
                        textTransform={"capitalize"}
                        className={classes.userNameText}
                      >
                        {item?.name}
                      </Typography>
                      <Typography className={classes.userEmailText}>
                        {item?.email}
                      </Typography>
                    </div>
                    <div>
                      <Typography>
                        {count[index]?.unseenEmailCount}/{count[index]?.totalEmailCount} Emails
                      </Typography>
                    </div>
                  </Box>
                </li>
              );
            })}
          </ul>
        </div>
        <Button
          variant="contained"
          className={classes.ModalSubmitBtn}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Box>
    </Modal>
  );
};

export default EmailManagerModel;
