import React, { useState } from "react";
import classes from "./user.module.css";
import "./users.css"; // Add your CSS file for styles
import {
  Avatar,
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { splitFirstLetter } from "../../utils/helper";
import { MdDelete } from "react-icons/md";
import ConfirmationPop from "../../popups/ConfirmationPop";
import { useDispatch } from "react-redux";
import { BsPlusSquareFill } from "react-icons/bs";
import { FaSearch } from "react-icons/fa";
import { MdAddCircle } from "react-icons/md";
import { deleteConversation } from "../../api/Api";
import { errorState, successState } from "../../redux/reducers/UserReducer";
import CreatePop from "../popup/CreatePop";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

function SubSideBar({ convoInfo, handleConversationInfo }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [createPopup, setCreatePopup] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen); // Toggle between true and false
  };

  const handleDeleteClick = async (e, item) => {
    e.stopPropagation();
    setSelectedItem(item);
    setConfirmationPopup(true);
  };

  const handleDeleteConversation = async () => {
    setConfirmationPopup(false);
    let result = await deleteConversation(selectedItem?.conversationInfo?._id);
    if (result?.status === 201 || result?.status === 200) {
      dispatch(successState(result?.data?.message));
      handleConversationInfo();
    } else {
      dispatch(
        errorState(
          result?.data?.message ? result?.data?.message : result?.statusText
        )
      );
    }
  };

  return (
    <Box
      className={`${classes.sidebar} ${isOpen ? classes.open : classes.closed}`}
    >
      <Button className={classes.toggleButton} onClick={toggleSidebar}>
        {isOpen ? (
          <IoIosArrowBack className={classes.toggle_button_icon} />
        ) : (
          <IoIosArrowForward className={classes.toggle_button_icon} />
        )}
      </Button>
      <div className={classes.menuItems}>
        <ul>
          <li>
            <Button
              className={classes.createConvoBtn}
              onClick={() => setCreatePopup(true)}
            >
              <p>Chat</p>
              <BsPlusSquareFill className={classes.createConvoBtn_icon} />
            </Button>
          </li>
          <li>
            <TextField
              fullWidth
              size="small"
              placeholder="Search"
              focused={false}
              InputProps={{
                disableUnderline: true,
                className: classes.customTextField,
                startAdornment: (
                  <InputAdornment position="start">
                    <FaSearch style={{ marginRight: "8px" }} />{" "}
                    {/* Adjust margin as needed */}
                  </InputAdornment>
                ),
              }}
              style={{ border: "none", borderRadius: "10px" }} // No need for !important
            />
          </li>
          {convoInfo &&
            convoInfo.length > 0 &&
            convoInfo.map((item) => {
              const { name } = item;
              const nameArr = item?.conversationInfo?.name?.split("-");
              return (
                <li>
                  <Box
                    className={`${classes.sidebar_logo}`}
                    key={item.conversationInfo._id}
                    onClick={() =>
                      navigate(`/user-page/${item.conversationInfo._id}`, {
                        state: item,
                      })
                    }
                  >
                    <Avatar
                      className={`${
                        window.location.pathname?.includes(
                          item.conversationInfo._id
                        )
                          ? classes.SelectedAvatar
                          : classes.unSelectedAvatar
                      }`}
                    >
                      {nameArr && splitFirstLetter(nameArr[1])}
                    </Avatar>
                    <div className={classes.sidebar_chat_info}>
                      <div className={classes.user_lastmessage}>
                        <Typography
                          textTransform={"capitalize"}
                          className={
                            !window.location.pathname?.includes(
                              item.conversationInfo._id
                            )
                              ? classes.UserNameText
                              : classes.SelectedUserNameText
                          }
                        >
                          {nameArr && nameArr[1]}
                        </Typography>
                        <Typography className={classes.message_time}>18:30</Typography>
                      </div>
                      <div className={classes.user_lastmessage}>
                        <Typography className={classes.last_message}>Ok, see you later</Typography>
                        <Typography className={classes.unread_count}>2</Typography>
                      </div>
                    </div>

                    {/* <Button
                      className={classes.deleteBinIcon}
                      onClick={(e) => handleDeleteClick(e, item)}
                    >
                      <MdDelete />
                    </Button> */}
                  </Box>
                </li>
              );
            })}
        </ul>
      </div>
      <ConfirmationPop
        open={confirmationPopup}
        handleClose={() => setConfirmationPopup(false)}
        handleConfirm={handleDeleteConversation}
        description={`Are you Want to Delete Conversation "${
          selectedItem?.conversationInfo?.name.split("-")[1]
        }"`}
      />
      <CreatePop open={createPopup} handleClose={() => setCreatePopup(false)} />
    </Box>
  );
}

export default SubSideBar;
