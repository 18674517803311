import React, { useState } from "react";
import classes from "./user.module.css";
import Pagination from "../common/pagination";
import TextToSpeech from "./textToSpech";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { IoCheckmarkDone, IoCopyOutline } from "react-icons/io5";
import dayjs from "dayjs";
import copy from "copy-to-clipboard";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import {
  AiFillDislike,
  AiFillLike,
  AiOutlineDislike,
  AiOutlineLike,
} from "react-icons/ai";
import { RiLoopLeftFill } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { errorState, successState } from "../../redux/reducers/UserReducer";
import { RegenerateMessage, sendFeedback } from "../../api/Api";

const Messages = ({
  datas,
  index,
  isJson,
  parsedMessage,
  initialsMsgs,
  setInitialMsgs,
  setIsPaginated,
  handleConversationInfo,
  setValue,
}) => {
  const dispatch = useDispatch();
  const _token = JSON.parse(localStorage.getItem("userToken"));
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page, totalPages) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const copyText = (text) => {
    copy(text);
    dispatch(successState("Copied Successfully"));
  };

  const handleRegenerateMsgs = async (id) => {
    let result = await RegenerateMessage(id);
    if (result?.status === 201 || result?.status === 200) {
      setIsPaginated(false);
      handleConversationInfo();
      setValue("");
      // dispatch(updateConversationInfo(result?.data?.data[0]));
    } else {
      dispatch(
        errorState(
          result?.data?.message ? result?.data?.message : result?.statusText
        )
      );
    }
  };

  const handleSendFeedback = async (id, message) => {
    const userEntry = {
      responce_feedback: message,
    };

    let result = await sendFeedback(id, userEntry);
    if (result?.status === 201 || result?.status === 200) {
      const messages = initialsMsgs.map((msg) => {
        if (msg?._id === id) {
          msg.responce_feedback = message;
        }
        return msg;
      });

      setInitialMsgs(messages);
    } else {
      dispatch(
        errorState(
          result?.data?.message ? result?.data?.message : result?.statusText
        )
      );
    }
  };

  // Extract the initials from the sender's name
  const initials = datas.senderInfo?.name
    ? datas.senderInfo?.name
        .split(" ")
        .map((word) => word[0].toUpperCase())
        .join("")
    : "Unknown User";

  return (
    <>
      {datas?.senderInfo?._id === _token?._id ? (
        <Box className={classes.my_msg} key={index}>
          <Box>
            <Typography
              maxWidth={"80%"}
              minWidth={{
                xs: "220px",
                sm: "270px",
                md: "300px",
              }}
            >
              {datas?.file_type?.includes("image") && (
                <Box width={"200px"}>
                  <img
                    src={`${process.env.REACT_APP_API_URL}/api/${datas?.file_url}`}
                    alt="i"
                    width={"100%"}
                  />
                </Box>
              )}
              {datas?.file_type?.includes("audio") && (
                <Box
                  width={{
                    xs: "250px",
                    sm: "270px",
                    md: "300px",
                  }}
                >
                  <audio
                    src={`${process.env.REACT_APP_API_URL}/api/${datas?.file_url}`}
                    style={{ width: "100%" }}
                    controls
                  ></audio>
                </Box>
              )}
              <span className={classes.msg_text}>
                {datas.message}
                <div>
                  {datas.senderInfo?.name
                    ? datas.senderInfo?.name
                        .split(" ")
                        .map((word) => word[0].toUpperCase())
                        .join("")
                    : "Unknown User"}
                </div>
              </span>
              <span className={classes.msg_time}>
                {dayjs(datas.time).format("hh:mm a")}
                <IoCheckmarkDone />
              </span>
            </Typography>
          </Box>
        </Box>
      ) : (
        datas?.message !== "" && (
          <Box className={classes.res_msg} key={index}>
            <Typography
              maxWidth={"85%"}
              minWidth={{
                xs: "220px",
                sm: "270px",
                md: "300px",
              }}
            >
              {isJson && parsedMessage.length > 0 ? (
                parsedMessage.map((data, index) => {
                  if (index + 1 === currentPage) {
                    return (
                      <Markdown key={index} rehypePlugins={[rehypeRaw]}>
                        {`<span class="${classes.msg_text}">
                           <div>${initials}</div>
                           <p>${data}</p>
                         </span>`}
                      </Markdown>
                    );
                  }
                  return null;
                })
              ) : (
                <Markdown rehypePlugins={[rehypeRaw]}>
                  {`<span class="${classes.msg_text}">
                    <div>${initials}</div>
                    <p>${datas.message}</p>
                  </span>`}
                </Markdown>
              )}
              <span className={classes.msg_time_user}>
                {dayjs(datas.time).format("hh:mm a")}
              </span>
            </Typography>

            <div className={classes.iconsPart}>
              {parsedMessage && parsedMessage.length > 0 && (
                <Pagination
                  total={parsedMessage.length}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                />
              )}
              <TextToSpeech message={datas.message} />
              <Tooltip title="Copy" arrow>
                <Button onClick={() => copyText(datas.message)}>
                  <IoCopyOutline />
                </Button>
              </Tooltip>
              <Tooltip title="Regenerate" arrow>
                <Button onClick={() => handleRegenerateMsgs(datas._id)}>
                  <RiLoopLeftFill />
                </Button>
              </Tooltip>
              {!datas?.responce_feedback ||
              datas?.responce_feedback === "good" ? (
                <Tooltip title="Good Response" arrow>
                  <Button onClick={() => handleSendFeedback(datas._id, "good")}>
                    {datas?.responce_feedback === "good" ? (
                      <AiFillLike />
                    ) : (
                      <AiOutlineLike />
                    )}
                  </Button>
                </Tooltip>
              ) : null}
              {!datas?.responce_feedback ||
              datas?.responce_feedback === "bad" ? (
                <Tooltip title="Bad Response" arrow>
                  <Button onClick={() => handleSendFeedback(datas._id, "bad")}>
                    {datas?.responce_feedback === "bad" ? (
                      <AiFillDislike />
                    ) : (
                      <AiOutlineDislike />
                    )}
                  </Button>
                </Tooltip>
              ) : null}
            </div>
          </Box>
        )
      )}
    </>
  );
};

export default Messages;
