import { Avatar, Box, Button, Tooltip, Typography } from "@mui/material";
import { IoSettings } from "react-icons/io5";
import classes from "./user.module.css";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar } from "../../redux/reducers/UserReducer";
import { FiLogOut } from "react-icons/fi";
import { splitFirstLetter } from "../../utils/helper";
import ConfirmationPop from "../../popups/ConfirmationPop";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { IoMdAlarm } from "react-icons/io";
import { MdEmail, MdOutlineMail, MdOutlineMessage } from "react-icons/md";
import Logo2 from "../../assets/images/logo-mygenio.png";
import CreatePop from "../popup/CreatePop";
import EmailManagerModel from "../common/EmailManagerModel";

function Sidebar({ open, setOpen }) {
  const location = useLocation();
  // const { open, handletoggle } =useOutletContext();
  const { pathname } = location;
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [createPopup, setCreatePopup] = useState(false);
  const { sidebarToggle, convoInfo } = useSelector((state) => state.user);
  const [emailmanagerPopup, setEmailManagerPopup] = useState(false);
console.log(pathname);


  const dispatch = useDispatch();
  const navigate = useNavigate();

  function handleConfirmLogout() {
    setConfirmPopup(false);
    localStorage.removeItem("userToken");
    navigate("/");
  }
  const handleToggle = () => {
    setOpen(!open);
    console.log("wookihdj");
  };
  return (
    <Box
      className={classes.sidebar_container}
      display={{ xs: "none", sm: "none", md: "block" }}
    >
      <Box className={classes.short_sidebar} mt={2}>
        <Box className={classes.sideBarMainContainer}>
          <img
            src={Logo2}
            alt="logo"
            className={classes.LogoStyles}
            style={{ cursor: "pointer" }} // Added pointer cursor for better UX
          />
          <Tooltip title="Chats" placement="right" arrow className=" ">
            <Typography
              textAlign={"center"}
       
              sx={{ cursor: "pointer" }}
              onClick={() => navigate("/user-page")}
            >
              <MdOutlineMessage className={classes.sidebar_icons} />
            </Typography>
          </Tooltip>
          <Tooltip title="Emails" placement="right" arrow className=" ">
            <Typography
              textAlign={"center"}
      
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/email");
                handleToggle();
              }}
            >
              <MdOutlineMail  className={classes.sidebar_icons}/>      
           
            </Typography>
          </Tooltip>
        </Box>
        <Box className={classes.sideBarMainContainer}>
          <Tooltip
            title="Settings"
            placement="right"
            arrow

          >
            <Typography
              textAlign={"center"}
    
              sx={{ cursor: "pointer" }}
              onClick={() => setEmailManagerPopup(true)}
            >
              <IoSettings  className={classes.sidebar_icons} />
            </Typography>
          </Tooltip>
          <Tooltip title="Reminder" placement="right" arrow>
            <Typography
              textAlign={"center"}

              sx={{ cursor: "pointer" }}
              onClick={() => navigate("/reminder")}
            >
              <IoMdAlarm className={classes.sidebar_icons}/>
            </Typography>
          </Tooltip>
          <Button
            className={classes.logout_btn}
            sx={{ padding: "18px 30px" }}
            onClick={() => setConfirmPopup(true)}
          >
            <FiLogOut style={{fontSize:"32px"}} />
          </Button>
        </Box>
      </Box>
      <ConfirmationPop
        open={confirmPopup}
        handleClose={() => setConfirmPopup(false)}
        handleConfirm={handleConfirmLogout}
        description={"Are you sure you want to logout ?"}
      />
      <CreatePop open={createPopup} handleClose={() => setCreatePopup(false)} />
      <EmailManagerModel
        open={emailmanagerPopup}
        handleClose={() => setEmailManagerPopup(false)}
      />
    </Box>
  );
}

export default Sidebar;
