import { createBrowserRouter, Navigate } from "react-router-dom";
import DefaultLayout from "./layout/DefaultLayout";
import ForgotPassword from "./components/auth/ForgotPassword";
import NewRegister from "./components/auth/NewRegister";
import UserLayout from "./layout/UserLayout";
import UserPage from "./components/users/UserPage";
import Reminder from "./components/reminder/Reminder";
import EmailIndex from "./components/Email/index";
import ComposeMail from "./components/compose";

const Router = createBrowserRouter([
  {
    path: "/",
    element: <DefaultLayout />,
    children: [
      {
        path: "/",
        element: <Navigate to="/login" />,
      },
      {
        path: "/login",
        element: <NewRegister />,
      },
      {
        path: "/reset-password",
        element: <ForgotPassword />,
      },
    ],
  },
  {
    path: "/",
    element: <UserLayout />,
    children: [
      {
        path: "/user-page/success",
        element: <UserPage />,
      },
      {
        path: "/user-page/:id",
        element: <UserPage />,
      },
      {
        path: "/user-page",
        element: <UserPage />,
      },
      {
        path: "/reminder",
        element: <Reminder />,
      },
      {
        path: "/email",
        element: <EmailIndex />,
      },
    ],
  },
]);

export default Router;
