import React, { useEffect, useState } from "react";
import classes from "./email.module.css";
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Typography,
} from "@mui/material";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { splitFirstLetter } from "../../utils/helper";
import { MdArrowForwardIos, MdDelete } from "react-icons/md";
import CreatePop from "../popup/CreatePop";
import Loader from "./Loader";
import { updateseendemail } from "../../api/Api";
import ConfirmationPopup from "./ConfirmationPopup";

const EmailMessageSubSideBar = ({
  handleManyDelete,
  emailsList,
  setSelectedEmail,
  setSecSidebaropen,
  secSidebaropen,
  loading,
  handelDeleteEmail,
  setMoreEmailId,
  MoreEmailId,
  manyLoading,
}) => {
  const navigate = useNavigate();
  const [createPopup, setCreatePopup] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false); // State for confirmation popup

  const toggleSidebar = () => {
    setSecSidebaropen(!secSidebaropen);
  };

  const extractName = (input) => {
    const match = input.match(/"([^"]*)"/);
    return match ? match[1] : null;
  };

  const handleseened = async (id) => {
    try {
      await updateseendemail(id);
      console.log("updated");
    } catch (error) {
      console.log(error);
    }
  };

  // Effect to maintain selection when emailsList changes
  useEffect(() => {
    const updatedSelections = MoreEmailId.filter((id) =>
      emailsList.some((item) => item.email_id === id)
    );
    setMoreEmailId(updatedSelections); // Update state with the remaining selected IDs
  }, [emailsList, setMoreEmailId]);

  // Function to handle select all
  const handleSelectAll = () => {
    if (MoreEmailId.length === emailsList.length) {
      setMoreEmailId([]);
    } else {
      const allEmailIds = emailsList.map((item) => item.email_id);
      setMoreEmailId(allEmailIds);
    }
  };

  // Function to handle individual checkbox selection
  const handleCheckboxChange = (e, email_id) => {
    if (MoreEmailId.includes(email_id)) {
      setMoreEmailId(MoreEmailId.filter((id) => id !== email_id));
    } else {
      setMoreEmailId([...MoreEmailId, email_id]);
    }
  };

  // Function to handle delete confirmation
  const handleDeleteConfirmation = () => {
    handleManyDelete(); // Call the original delete function
    setMoreEmailId([]); // Optionally clear selections after deletion
    setConfirmationOpen(false); // Close the confirmation dialog
  };

  // Function to handle delete button click
  const handleDeleteButtonClick = () => {
    if (MoreEmailId.length === 0) {
      // If no emails are selected, show confirmation popup with a message
      alert("Please select emails to delete."); // You can replace this with a modal popup as well
    } else {
      // Show confirmation popup
      setConfirmationOpen(true);
    }
  };
  function createSortNameFromEmail(email) {
    const initials = email.slice(0, 2).toUpperCase().split("").join(".");

    return initials;
  }

  // Example usage:
  return (
    <>
      {manyLoading ? (
        <div className={classes.loading}>
          <Loader />
        </div>
      ) : (
        <Box
          className={`${classes.sidebar} ${classes.small_navbar} ${
            secSidebaropen ? classes.open : classes.closed
          }`}
        >
          <Button
            className={`${classes.toggleButton} ${classes.toggleButton2}`}
            onClick={toggleSidebar}
          >
            {secSidebaropen ? (
              <MdArrowForwardIos className={classes.icon} />
            ) : (
              <FaChevronCircleRight className={classes.icon_close} />
            )}
          </Button>
          <div className={classes.small_navbar_select}>
            <div className="d-flex align-items-center ">
              <Button onClick={handleSelectAll}>
                {MoreEmailId.length === emailsList.length
                  ? "Deselect All"
                  : "Select All"}
              </Button>
              <Button color="error" onClick={handleDeleteButtonClick}>
                Delete
              </Button>{" "}
            </div>
            {/* Updated to use the new function */}
          </div>
          <div className={`${classes.menuItems} ${classes.menuItems_small}`}>
            <ul
              style={{ position: "relative" }}
              className={`d-flex flex-column align-items-start w-100 mt-2 gap-4`}
            >
              {emailsList &&
                emailsList.length > 0 &&
                emailsList.map((item) => {
                  const name = extractName(item.from);
                  return (
                    <li key={item._id} style={{ width: "100%" }}>
                      <Box
                        className={`${classes.sidebar_logo2}`}
                        onClick={(e) => {
                          // Check if the click was not on the checkbox
                          if (e.target.type !== "checkbox") {
                            setSelectedEmail(item);
                            handleseened(item._id);
                          }
                        }}
                      >
                        <div className={classes.small_navbar_details}>
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center gap-2 ">
                              <Avatar
                                className={classes.userAvatar}
                                style={{ backgroundColor: item.color }}
                              >
                                {name && splitFirstLetter(name)}
                              </Avatar>
                              <Typography
                                textTransform={"capitalize"}
                                className={classes.userNameText}
                              >
                                {name?.length > 15
                                  ? name.slice(0, 15) + "..."
                                  : name}
                              </Typography>
                            </div>
                            <div className={classes.email_subdetails}>
                              3:13 PM
                            </div>
                          </div>
                          <div className="d-flex align-items-center gap-3 justify-content-between">
                            <div className="d-flex align-items-center gap-3">
                              <Checkbox
                                className={classes.checkbox}
                                checked={MoreEmailId.includes(item.email_id)} // Check if the email ID is in the selected list
                                onChange={(e) => {
                                  e.stopPropagation(); // Prevent event bubbling
                                  handleCheckboxChange(e, item.email_id); // Call the individual handler
                                }} // Call the individual handler
                              />
                              <Typography
                                className={
                                  item.seened
                                    ? classes.userEmailText_seen
                                    : classes.userEmailText
                                }
                              >
                                {item?.subject.length > 60
                                  ? item?.subject.slice(0, 60) + "..."
                                  : item?.subject}
                              </Typography>
                            </div>

                            <div className={classes.email_subdetails_sender}>
                              FROM : <span style={{color:item.color}}>{createSortNameFromEmail(item.name)}</span>
                            </div>
                          </div>
                        </div>
                        <div></div>
                        {/* <div>
                        <Typography>
                          {loading === item.email_id ? (
                            <Loader width={20} />
                          ) : (
                            <MdDelete
                              className={classes.delete_icon}
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent event bubbling to the Box
                                handelDeleteEmail(item.email_id);
                              }}
                            />
                          )}
                        </Typography>
                      </div> */}
                      </Box>
                    </li>
                  );
                })}
            </ul>
          </div>
          <CreatePop
            open={createPopup}
            handleClose={() => setCreatePopup(false)}
          />
          <ConfirmationPopup
            open={confirmationOpen}
            onClose={() => setConfirmationOpen(false)}
            onConfirm={handleDeleteConfirmation}
          />
        </Box>
      )}
    </>
  );
};

export default EmailMessageSubSideBar;
